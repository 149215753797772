import dayjs from "dayjs";
import "dayjs/locale/en";
import "dayjs/locale/hu";
import "dayjs/locale/ro";
import localizedFormat from "dayjs/plugin/localizedFormat";
import relativeTime from "dayjs/plugin/relativeTime";
import { useTranslation } from "react-i18next";
import { IOrder } from "../pages/InProgress";
import ReprogramDelivery, { useStyle } from "./ReprogramDelivery";

interface IProps {
  order: IOrder;
  updateDeliveryDate: (
    orderId: string,
    date: Date,
    reason: string,
    time: string
  ) => void;
}
dayjs.extend(relativeTime);
dayjs.extend(localizedFormat);

function OrderInfo({ order, updateDeliveryDate }: IProps) {
  const { classes } = useStyle();
  const { t, i18n } = useTranslation();
  // Use Day.js in your component
  dayjs.locale(i18n.language);

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "flex-start",
        alignItems: "flex-start",
        gap: "10px",
        fontSize: "20px",
        fontWeight: "bold",
        width: "100%",
        padding: "10px",
        boxSizing: "border-box",
      }}
    >
      <div className={classes.key}>
        {t("labelId")}:{" "}
        <span className={classes.values}>{order?.generatedTicketId}</span>
      </div>
      <div className={classes.key}>
        {t("labelName")}: <span className={classes.values}>{order?.name}</span>
      </div>
      <div className={classes.key}>
        {t("labelPhone")}:{" "}
        <span className={classes.values}>{order?.phone}</span>
      </div>
      <div className={classes.key}>
        {t("labelNrOfProducts")}:{" "}
        <span className={classes.values}>{order?.initialProductsNumber}</span>
      </div>
      <div className={classes.key}>
        {t("labelDatePickup")}:{" "}
        <span className={classes.values}>
          {dayjs(order.pickupDate).format("LLLL")}{" "}
        </span>
      </div>
      {order.status === "forDelivery" && (
        <ReprogramDelivery
          order={order}
          updateDeliveryDate={updateDeliveryDate}
          aproxDeliveryDateFormatted={dayjs(order.aproximateDelivery).format(
            "dddd, D MMMM YYYY"
          )}
          aproximateHourDelivery={order.aproximateHourDelivery}
        />
      )}
      {order.status === "delivered" && (
        <div className={classes.key}>
          {t("labelDateDelivery")}:{" "}
          <span className={classes.values}>
            {dayjs(order.deliveryDate).format("LLLL")}
          </span>
        </div>
      )}
    </div>
  );
}
export default OrderInfo;
